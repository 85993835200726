<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="true"
          title="Workflow Approval List"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
      />
      <div class="row">
        <div class="col-10">
          <label for="colFormLabel" class="col-form-label">Search</label>
          <input class="form-control" type="text" name="status" v-model="quick_search">
        </div>
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              @click="onClickSearchButton"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
          >Go
          </button>
        </div>
      </div>
    </div>

    <ListTable/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
  </div>
</template>

<script setup>
import TitleButton     from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable       from '@/components/molecule/procurement/organization-settings/approval-matrix/WorkflowApprovalListingTable.vue'
import Pagination      from '@/components/atom/Pagination'
import Loader          from '@/components/atom/LoaderComponent'
import {inject}        from "vue";

function onClickAddNewButton() {
  alert('Add New Button clicked');
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

function onClickSearchButton() {
  alert('Search Button clicked');
}

function getJournals() {
  //todo
}

function onPageChange() {
  //todo
}
</script>
